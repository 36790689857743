// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("/Users/jesse/Development/zipline/marketing-website/src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-use-case-page-js": () => import("/Users/jesse/Development/zipline/marketing-website/src/templates/use-case-page.js" /* webpackChunkName: "component---src-templates-use-case-page-js" */),
  "component---src-pages-index-js": () => import("/Users/jesse/Development/zipline/marketing-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-why-index-js": () => import("/Users/jesse/Development/zipline/marketing-website/src/pages/why/index.js" /* webpackChunkName: "component---src-pages-why-index-js" */)
}

